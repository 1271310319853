.card{
    width: 94%;
    border-radius: 2px;
    background-color: white;
    display: inline-flex; 
    margin: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px ;
    cursor: pointer;
}

.imagen{
    width: 30%;
    height: 30%;
    display: flex;
}

.center{
    display: flex;
    justify-content: center;
}
.scroll{
    margin:4px, 4px;
    padding:4px;
    background-color: white;
    width: 100%;
    height: 90%;
    overflow-x: hidden;
    overflow-y: auto;
    text-align:justify;
}
